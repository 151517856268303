import React from "react"
import Helmet from 'react-helmet'

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
        {/* General tags */}
        <title>404: Page Not found</title>
        <meta name="robots" content="noindex, follow" />
    </Helmet>
    <h1>Oh no, you're trying to go somewhere you shouldn't!</h1>
    <p>
        Don't worry try using the following links and hopefully we can get you to the right place ;)
        <br />
        <ul>
            <li><a href="/posts">Read some blog posts</a></li>
            <li><a href="/">Check out the very exciting homepage</a></li>
            <li><a href="/about">Read more about Ilesh, why not?</a></li>
        </ul>
    </p>
  </Layout>
)

export default NotFoundPage